@charset "UTF-8";
.down_btn[data-v-13416422] {
  position: absolute;
  right: 0;
}
.demo-image__preview[data-v-13416422] {
  text-align: center;
}
.switchover[data-v-13416422] {
  width: 20px;
  text-align: center;
}
.switchover .two_btn[data-v-13416422] {
  font-size: 30px;
  color: #362721;
  line-height: 60px;
  background: #eee;
  cursor: pointer;
}
.switchover .arrow_left[data-v-13416422] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.switchover .arrow_right[data-v-13416422] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.each[data-v-13416422] {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.pic_style[data-v-13416422] {
  width: 100%;
  margin: 0 auto;
  display: flex;
  background-image: linear-gradient(180deg, rgba(23, 162, 184, 0.2) 0%, rgba(25, 182, 199, 0) 100%);
  padding: 2px 0;
  flex-wrap: wrap;
}
.bottom_com[data-v-13416422] {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.bottom_com span[data-v-13416422] {
  cursor: pointer;
}
.pic_box[data-v-13416422] {
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  width: 84px;
}
.pic_box img[data-v-13416422] {
  border: 2px solid #efefef;
}
.pic_image_box[data-v-13416422] {
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  width: 84px;
}
.pic_image_box img[data-v-13416422] {
  border: 2px solid #efefef;
}
.pic_image_box[data-v-13416422]::before {
  content: ""; /* 创建伪元素 */
  position: absolute; /* 绝对定位 */
  top: 2px; /* 与正方形顶部对齐 */
  right: 2px; /* 与正方形右侧对齐 */
  width: 0;
  height: 0;
  border-width: 16px;
  border-style: dashed;
  border-color: #fff #fff transparent transparent;
}
.active[data-v-13416422] {
  border-color: #6aa2e7 !important;
}
.iconfont[data-v-13416422] {
  font-size: 14px;
}
.sj_pic[data-v-13416422] {
  padding: 5px;
  box-shadow: 0 2px 10px #ddd;
}
.title[data-v-13416422] {
  width: 84px;
  font-size: 11px;
}