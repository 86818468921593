
.el-carousel__indicator.is-active button {
  background-color: #17a2b8;
}
.el-carousel__button {
  background-color: gray;
  height: 4px;
}
.dialog_style .el-dialog__body {
  padding: 15px !important;
}
